import * as moment from 'moment-timezone';
import api from './api';

export default {
  get() {
    const path = '/api/v1/producers';

    return api({
      method: 'get',
      url: path,
    });
  },
  permissions() {
    const path = '/api/v1/producers/0/permissions';

    return api({
      method: 'get',
      url: path,
    });
  },
  getInactiveReservations(id, date) {
    const path = `/api/v1/producers/${id}/inactive_reservations`;

    return api({
      method: 'get',
      url: path,
      params: { date: moment(date).format('YYYY-MM-DD') },
    });
  },
};
