import VueRouter from 'vue-router';
import CalendarPage from '../pages/calendar-page';
import ReservationForm from '../components/reservation-form';
import ReservationDetail from '../components/reservation-detail';
import PendingChallenges from '../components/pending-challenges';
import InactiveReservations from '../components/inactive-reservations';
import TransferDetail from '../components/transfer-detail';

const routes = [
  {
    path: '/',
    component: CalendarPage,
    props: true,
    children: [
      {
        path: '/new',
        component: ReservationForm,
      },
      {
        path: '/reservation/:id',
        component: ReservationDetail,
      },
      {
        path: '/pendings',
        component: PendingChallenges,
      },
      {
        path: '/inactives/:day',
        component: InactiveReservations,
      },
      {
        path: '/transfer/:id',
        component: TransferDetail,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
export default router;
