<template>
  <div
    v-if="currentUser"
    class="calendar-page"
  >
    <header-user
      class="calendar-main-header"
      :user-name="userName"
      :user-email="currentUser.email"
    />
    <header-user-navigation
      :logout-path="logoutPath"
      :current-user="currentUser"
    />
    <calendar
      class="calendar"
      :arena-types="arenaTypes"
    />
    <calendar-sidebar
      class="calendar-sidebar"
      v-if="canSeeSidebar"
      :arena-types="arenaTypes"
    />
  </div>
</template>

<script>

import Calendar from '../components/calendar';
import HeaderUser from '../components/header-user';
import CalendarSidebar from '../components/calendar-sidebar';
import HeaderUserNavigation from '../components/header-user-navigation';

import { INIT } from '../store/action-types';

export default {
  name: 'CalendarPage',
  props: {
    logoutPath: {
      type: String,
      default: () => '/',
    },
    currentUser: {
      type: Object,
      default: () => null,
    },
    arenaTypes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    userName() {
      return this.currentUser.producer ? this.currentUser.producer.name : 'Admin';
    },
    profilePath() {
      return this.currentUser.producer ? `/users/${this.currentUser.id}` : '/admin';
    },
    canSeeSidebar() {
      return !!this.currentUser.producer ||
        (this.currentUser.role && !['trustee_admin', 'viewer_admin'].includes(this.currentUser.role));
    },
  },
  created() {
    this.initializeStore();
  },
  components: {
    HeaderUser,
    Calendar,
    CalendarSidebar,
    HeaderUserNavigation,
  },
  methods: {
    initializeStore() {
      this.$store.dispatch(INIT, { currentUser: this.currentUser, arenaTypes: this.arenaTypes });
    },
    showNavigation() {
      document.getElementById('headerUserNavigation').style.display = 'block';
    },
    hideNavigation() {
      document.getElementById('headerUserNavigation').style.display = 'none';
    },
    goToFAQ() {
      document.location = '/faq';
    },
    goToProfile() {
      document.location = this.profilePath;
    },
  },
};
</script>
