import * as moment from 'moment-timezone';

export default function formatRate(
  currentRate,
  { startDate, endDate, mountingDays, dismountingDays },
) {
  const defaultValues = {
    startDate: '',
    endDate: '',
    mountingDays: 0,
    dismountingDays: 0,
  };
  const {
    startDate: startDateValue,
    endDate: endDateValue,
    mountingDays: mountingDaysValue,
    dismountingDays: dismountingDaysValue,
  } = Object.assign(defaultValues, {
    startDate,
    endDate,
    mountingDays: Number(mountingDays) || 0,
    dismountingDays: Number(dismountingDays) || 0,
  });
  if (!currentRate) return '';
  const numberOfDays =
    moment(endDateValue).diff(moment(startDateValue), 'days') + 1;
  const totalRate =
    currentRate.rate * numberOfDays +
    currentRate.mountingRate * (mountingDaysValue + dismountingDaysValue);
  const totalRateUF = totalRate.toLocaleString('es-CL', {
    minimumFractionDigits: 0,
  });
  const variableRate = currentRate.variableRate ?
    ` + ${currentRate.variablePercentage}` :
    '';

  return `UF ${totalRateUF}${variableRate} + IVA`;
}
