import api from './api';

export default {
  get() {
    const path = '/api/v1/duels';

    return api({
      method: 'get',
      url: path,
    });
  },
};
