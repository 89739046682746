<template>
  <div>
    <h1 class="sidebar__subform-title">
      Nuevo intento de reasignación
    </h1>
    <div class="sidebar__body">
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Reserva reagendada
        </div>
        <v-select
          v-model="rescheduledReservationId"
          :options="rescheduledOptions"
          :reduce="reservation => reservation.id"
        />
      </div>
    </div>
    <ul
      v-if="errors.length > 0"
      class="sidebar__errors"
    >
      <li
        class="sidebar__error field-error"
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </li>
    </ul>
    <div class="reservation-form__actions sidebar__actions">
      <button
        class="sidebar__action"
        @click="confirmReassignReservation()"
      >
        Confirmar
      </button>
    </div>
  </div>
</template>
<script>

import { es } from 'vuejs-datepicker/dist/locale';
import * as actions from '../store/action-types';

export default {
  name: 'ReassignForm',
  props: {
    reservation: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      rescheduledReservationId: null,
      es,
    };
  },
  computed: {
    errors() {
      return this.$store.state.calendar.errors;
    },
    rescheduleReservations() {
      const producerId = this.reservation.producerId;
      const rescheduledReservations = this.$store.getters.rescheduledReservations();

      return rescheduledReservations.filter(reservation => reservation.producerId === producerId);
    },
    rescheduledOptions() {
      return this.rescheduleReservations.map(reservation =>
        ({ label: `${reservation.artist.name} - ${reservation.id}`, ...reservation }));
    },
  },
  methods: {
    confirmReassignReservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmReassignReservationDataInfo(),
      );
    },
    confirmReassignReservationDataInfo() {
      return {
        header: 'Estás a punto de confirmar esta reserva y reasignar la seleccionada',
        body: '¿Estás seguro de realizar esta acción?',
        button: 'Volver',
        action: {
          name: 'Sí, confirmar y reasignar.',
          exec: this.reassignAndConfirmReservation,
        },
      };
    },
    reassignAndConfirmReservation() {
      if (this.reservation.canStartConfirmationJourney) {
        this.$store.dispatch(
          actions.START_CONFIRMATION_JOURNEY,
          {
            reservation: this.reservation,
            tentativeRescheduledReservationId: this.rescheduledReservationId,
            options: {
              modal: this.reservation.firstPriority ?
                'confirmation_process_modal' : 'confirmation_journey_modal',
            },
          },
        );
        this.$store.dispatch(actions.CLOSE_MODAL);
        this.$emit('reassign-form');
      } else {
        this.$store.dispatch(
          actions.OPEN_MODAL,
          this.mustCreditModalDataInfo('confirmar'),
        );
      }
    },
    mustCreditModalDataInfo(reason) {
      return {
        header: `No puedes ${reason} aún`,
        body: `Para ${reason} debes realizar un abono de $5.000.000 a la cuenta:\n` +
          'Una vez validado el abono, te enviaremos un correo.',
        button: 'Entendido',
      };
    },
  },
};
</script>
