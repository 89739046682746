import * as humps from 'humps';
import api from './api';

export default {
  create(artist) {
    const path = '/api/v1/artists';

    return api({
      method: 'post',
      data: { artist: humps.decamelizeKeys(artist) },
      url: path,
    });
  },
  get() {
    const path = '/api/v1/artists';

    return api({
      method: 'get',
      url: path,
    });
  },
};
