<template>
  <div class="calendar">
    <div class="calendar__main">
      <div class="calendar__actions">
        <div class="calendar-nav calendar__nav">
          <div
            @click="goToPreviousMonth()"
            class="calendar__action calendar__button-left"
          />
          <div
            @click="goToNextMonth()"
            class="calendar__action calendar__button-right"
          />
          <datepicker
            @selected="onMonthSelected"
            ref="datepicker"
            :minimum-view="'month'"
            :maximum-view="'month'"
            :value="firstDay.toDate()"
            :format="calendarMonthFormatter"
            input-class="calendar__action calendar__month-picker"
          />
        </div>
      </div>
      <div class="calendar__content">
        <div class="calendar-header calendar__header">
          <div class="calendar-header__weekdays">
            <div
              class="calendar-header__weekday"
              v-for="day in weekDays"
              :key="day"
            >
              {{ day }}
            </div>
          </div>
        </div>
        <div class="calendar__body">
          <div
            class="calendar__row"
            v-for="week in totalWeeks"
            :key="week"
          >
            <div
              class="calendar__cell"
              v-for="(day, index) in weekDays"
              :key="day"
            >
              <calendar-cell
                :is-empty="emptyDay(week, index)"
                :date="calculateDateFromPosition(week, index)"
                :reservations="getReservationsForDate(week, index)"
                :inactive-reservations="getInactiveReservationsForDate(week, index)"
                :month="currentMonth"
                :year="currentYear"
                :holiday-name="holidayName(currentYear, currentMonth, calculateDateFromPosition(week, index))"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="modal">
      <modal
        v-show="showModal"
      />
    </transition>
  </div>
</template>

<script>

import * as humps from 'humps';
import * as moment from 'moment-timezone';
import 'moment/locale/es';
import throttle from 'lodash.throttle';
import Datepicker from 'vuejs-datepicker';

import CalendarCell from './calendar-cell';
import Modal from './modal';
import * as actions from '../store/action-types';

moment.locale('es-CL');
moment.tz.setDefault('America/Santiago');
const DAYS_IN_WEEK = 7;
const WEEKS_IN_A_YEAR = 53;

export default {
  name: 'Calendar',
  components: {
    CalendarCell,
    Modal,
    Datepicker,
  },
  props: {
    arenaTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      weekDays: [...Array(DAYS_IN_WEEK).keys()].map(dow => moment().weekday(dow).format('dddd')),
    };
  },
  created() {
    this.fetchReservations();
    this.subscribeToCalendarEvents();
  },
  channels: {
    ReservationsChannel: {
      connected() {
        console.log('connected');
      },
      received(event) {
        this.onReservationEventReceived(event);
      },
      disconnected() {
        console.log('disconnected');
      },
    },
  },
  computed: {
    firstDay() {
      return moment(this.$store.state.calendar.calendarDate);
    },
    selectedReservation() {
      return this.$store.getters.selectedReservation();
    },
    currentReservations() {
      return this.$store.getters.reservations();
    },
    currentFormattedMonth() {
      return this.firstDay.format('MMMM');
    },
    currentMonth() {
      return this.firstDay.month();
    },
    daysInMonth() {
      // eslint-disable-next-line no-magic-numbers
      return this.firstDay.daysInMonth();
    },
    totalWeeks() {
      const totalDays = this.daysInMonth + this.firstWeekday;

      return Math.ceil(totalDays / DAYS_IN_WEEK);
    },
    firstWeekday() {
      return this.firstDay.day() === 0 ? 6 : this.firstDay.day() - 1;
    },
    groupedReservations() {
      return this.$store.getters.reservationsGroupedByDay;
    },
    groupedInactiveReservations() {
      return this.$store.getters.inactiveReservationsGroupedByDay;
    },
    currentYear() {
      return this.firstDay.format('YYYY');
    },
    showModal() {
      return this.$store.state.calendar.showModal;
    },
  },
  methods: {
    calendarMonthFormatter(date) {
      return moment(date).format('MMMM YYYY');
    },
    onMonthSelected(date) {
      this.$store.dispatch(actions.CHANGE_MONTH, { date });
    },
    onReservationEventReceived: throttle(function (event) {
      this.$store.dispatch(
        actions.RESERVATION_EVENT_RECEIVED,
        humps.camelizeKeys(event)
      );
    }, 500, { leading: true, trailing: true }), // eslint-disable-line no-magic-numbers
    subscribeToCalendarEvents() {
      this.$cable.subscribe({ channel: 'ReservationsChannel' });
    },
    goToPreviousMonth() {
      this.$store.dispatch(actions.CHANGE_MONTH, {
        date: moment(this.firstDay.subtract(1, 'months')).toDate(),
      });
    },
    goToNextMonth() {
      this.$store.dispatch(actions.CHANGE_MONTH, {
        date: moment(this.firstDay.add(1, 'months')).toDate(),
      });
    },
    fetchReservations() {
      this.$store.dispatch(actions.CHANGE_MONTH, { date: this.firstDay.toDate() });
    },
    calculateDateFromPosition(week, index) {
      return (week - 1) * DAYS_IN_WEEK + (index + 1) - this.firstWeekday;
    },
    emptyDay(week, index) {
      return (week === 1 && this.firstWeekday > index) ||
      this.calculateDateFromPosition(week, index) > this.daysInMonth;
    },
    getReservationsForDate(week, index) {
      const dateReservations = this.groupedReservations[this.calculateDateFromPosition(week, index)];

      return dateReservations;
    },
    getInactiveReservationsForDate(week, index) {
      const dateInactiveReservations = this.groupedInactiveReservations[this.calculateDateFromPosition(week, index)];

      return dateInactiveReservations;
    },
    dateDayInCurrentMonth(date, currentDate) {
      if (moment(date).month() < moment(currentDate).month()) {
        return 1;
      } else if (moment(date).month() > moment(currentDate).month()) {
        return moment(currentDate).endOf('month').date();
      }

      return moment(date).date();
    },
    holidayName(year, month, day) {
      let name = '';
      this.$store.state.calendar.holidays.forEach(holiday => {
        const cellDate = moment(new Date(year, month, day)).format('YYYY-MM-DD');
        if (holiday.date === cellDate) {
          name = holiday.name;
        }
      });

      return name;
    },
  },
};
</script>
