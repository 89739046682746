<template>
  <div class="header-user-navigation">
    <div
      class="header-user__navigation"
      id="headerUserNavigation"
      @mouseover="showNavigation()"
      @mouseleave="hideNavigation()"
    >
      <div
        v-if="!isAdmin"
        class="header-user__navigation-option"
        @click="goToProfile()"
      >
        <div class="header-user__settings-icon" />
        <div class="header-user__option-label">
          Ajustes
        </div>
      </div>
      <div
        class="header-user__navigation-option"
        @click="goToFAQ()"
      >
        <div class="header-user__faq-icon" />
        <div class="header-user__option-label">
          FAQ
        </div>
      </div>
      <div
        v-if="isAdmin"
        class="header-user__navigation-option"
        @click="goToAdminPanel()"
      >
        <div class="header-user__settings-icon" />
        <div class="header-user__option-label">
          Panel de admin
        </div>
      </div>
      <div class="header-user__navigation-option">
        <div class="header-user__logout-icon" />
        <div
          class="header-user__option-label"
        >
          <a
            :href="logoutPath"
            data-method="delete"
            class="header-user__option-link"
          >
            Logout ({{ userName }})
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderUserNavigation',
  props: {
    logoutPath: {
      type: String,
      default: () => '/',
    },
    currentUser: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isAdmin() {
      return !this.currentUser.producer;
    },
    userName() {
      return this.currentUser.producer ? this.currentUser.producer.name : 'Admin';
    },
    profilePath() {
      return this.currentUser.producer ? `/users/${this.currentUser.id}` : '/admin';
    },
  },
  methods: {
    showNavigation() {
      document.getElementById('headerUserNavigation').style.display = 'block';
    },
    hideNavigation() {
      document.getElementById('headerUserNavigation').style.display = 'none';
    },
    goToFAQ() {
      document.location = '/faq';
    },
    goToProfile() {
      document.location = this.profilePath;
    },
    goToAdminPanel() {
      document.location = '/admin';
    },
  },
};
</script>
