<template>
  <header class="header">
    <img
      src="../../assets/images/logo-with-bg.jpg"
      class='header__logo'
    >
    <clipLoader
      class="header__spinner"
      :color="'#00a9e0'"
      :loading="loading"
      :size="15"
    />
    <div class="header__pendings">
      <div
        class="header__notifications"
        @click="onNotificationClicked()"
      >
        {{ pendingsCount }}
      </div>
    </div>
    <div class="header__user">
      <div class="header-user">
        <div
          class="header-user__identification"
          @mouseover="showNavigation()"
          @mouseleave="hideNavigation()"
        >
          <div class="header-user__name">
            {{ userName }}
          </div>
          <div class="header-user__account-icon" />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { ClipLoader } from '@saeris/vue-spinners';
import * as actions from '../store/action-types';

export default {
  name: 'HeaderUser',
  components: {
    ClipLoader,
  },
  props: {
    userName: {
      type: String,
      default: () => '',
    },
    userEmail: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    pendingsCount() {
      return this.$store.state.calendar.duels.length + this.$store.state.calendar.transferAttempts.length;
    },
    loading() {
      return this.$store.state.calendar.fetching;
    },
  },
  mounted() {
    this.hideNavigation();
  },
  methods: {
    onNotificationClicked() {
      this.$store.dispatch(actions.TOGGLE_PENDINGS);
    },
    showNavigation() {
      document.getElementById('headerUserNavigation').style.display = 'block';
    },
    hideNavigation() {
      document.getElementById('headerUserNavigation').style.display = 'none';
    },
  },
};
</script>

