<template>
  <div class="calendar-sidebar">
    <div class="calendar-sidebar__container">
      <div
        @click="addReservation()"
        class="calendar-sidebar__button calendar__button"
      >
        Agregar Reserva
      </div>
      <router-view
        :arena-types="this.arenaTypes"
        :key="$route.fullPath"
      />
    </div>
  </div>
</template>
<script>

import * as actions from '../store/action-types';

export default {
  name: 'CalendarSidebar',
  props: {
    arenaTypes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    addReservation() {
      this.$store.dispatch(actions.START_CREATING_RESERVATION);
    },
  },
};
</script>
