import * as moment from 'moment-timezone';
import * as humps from 'humps';
import api from './api';

export default {
  get(date) {
    const path = '/api/v1/reschedule_attempts';

    return api({
      method: 'get',
      url: path,
      params: { date: moment(date).format('YYYY-MM-DD') },
    });
  },
  create(rescheduleAttempt) {
    const path = '/api/v1/reschedule_attempts';

    const formData = new FormData();

    Object.keys(rescheduleAttempt).forEach(key => formData.append(humps.decamelize(key), rescheduleAttempt[key]));

    return api({
      method: 'post',
      data: formData,
      url: path,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};
