<template>
  <div
    class="reservation-detail"
    v-if="reservation"
  >
    <div class="reservation-detail__container sidebar reservation-detail__confirmed">
      <div
        class="reservation-detail__close sidebar__close"
        @click="close()"
      >
        <div class="close-icon" />
      </div>
      <h1
        class="reservation-detail__title sidebar__title"
        v-if="reservation.producerName"
      >
        Productora: {{ reservation.producerName }}
      </h1>
      <div class="reservation-detail__body sidebar__body">
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="time-icon" />
            Fecha de inicio:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.startDate | simpleDate }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="time-icon" />
            Fecha de término:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.endDate | simpleDate }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="mounting-icon" />
            Días de montaje:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.mountingDays }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="dismounting-icon" />
            Días de desmontaje:
          </div>
          <div class="reservation-detail__label sidebar__label">
            {{ reservation.dismountingDays }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="state-icon" />
            Estado Reserva:
          </div>
          <div class="reservation-detail__label sidebar__label reservation-state__confirmed">
            {{ reservationStates[reservation.state] }}
          </div>
        </div>
        <div class="reservation-detail__info sidebar__info">
          <div class="reservation-detail__label sidebar__label">
            <div class="state-icon" />
            Estado Cesión:
          </div>
          <div class="reservation-detail__label sidebar__label reservation-state__cannot-confirm">
            {{ transferAttemptStates[transferAttempt.state] }}
          </div>
        </div>
      </div>
      <div
        class="reservation-detail__body sidebar__body"
        v-if="transferAttempt.state === 'pending'"
      >
        <div class="sidebar__actions">
          <button
            class="sidebar__action"
            @click="handleAcceptTransferForm"
          >
            Aceptar
          </button>
          <button
            class="sidebar__action"
            @click="openRejectTransferModal"
          >
            Rechazar
          </button>
        </div>
      </div>
      <div
        class="reservation-detail__body sidebar__body"
        v-if="showAcceptTransferForm"
      >
        <accept-transfer-form
          :transfer-attempt="transferAttempt"
          @transfer-form="handleAcceptTransferForm()"
        />
      </div>
    </div>
  </div>
</template>
<script>

import * as moment from 'moment-timezone';
import * as actions from '../store/action-types';
import AcceptTransferForm from './accept-transfer-form.vue';

export default {
  name: 'TransferDetail',
  components: { AcceptTransferForm },
  data() {
    return {
      reservationStates: { 'confirmed': 'Confirmado' },
      transferAttemptStates: {
        'pending': 'Pendiente',
        'accepted_by_new_producer': 'Aceptada por productora',
        'accepted_by_admin': 'Aceptada por ejecutiva reservas',
      },
      showAcceptTransferForm: false,
    };
  },
  computed: {
    currentTransferAttemptId() {
      return parseInt(this.$route.params.id, 10);
    },
    transferAttempt() {
      return this.$store.state.calendar.transferAttempts.find(attempt => attempt.id === this.currentTransferAttemptId);
    },
    reservation() {
      return this.transferAttempt.reservation;
    },
  },
  filters: {
    simpleDate(unformattedDate) {
      const date = moment(unformattedDate);

      return `${date.date()} de ${date.format('MMMM')}`;
    },
  },
  methods: {
    close() {
      this.$store.dispatch(actions.REDIRECT_TO_ROOT);
    },
    openRejectTransferModal() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        {
          header: 'Estás a punto de rechazar esta cesión de fecha',
          body: '¿Estás seguro de realizar esta acción?',
          button: 'Volver',
          action: {
            name: 'Sí, rechazar',
            exec: this.rejectTransferAttempt,
          },
        },
      );
    },
    rejectTransferAttempt() {
      this.$store.dispatch(
        actions.UPDATE_TRANSFER_ATTEMPT, { id: this.transferAttempt.id, state: 'rejected_by_new_producer' },
      );
      this.$store.dispatch(actions.CLOSE_MODAL);
    },
    handleAcceptTransferForm() {
      this.showAcceptTransferForm = !this.showAcceptTransferForm;
    },
  },
};
</script>
