<template>
  <div class="pending-challenges">
    <div
      class="pending-challenges__close sidebar__close"
      @click="close()"
    >
      <div class="close-icon" />
    </div>
    <div
      class="pending-challenges__block"
      v-if="duelsAsChallenger.length > 0"
    >
      <div class="pending-challenges__list">
        <div
          class="pending-challenges__item pending-challenges-item"
          v-for="duel in duelsAsChallenger"
          :key="duel.id"
        >
          <div class="pending-challenges-item__section">
            <span class="pending-challenges__title">
              {{ currentUser.producer ? 'Estás desafiando' : 'Reserva desafiando' }}
            </span>
          </div>
          <div class="pending-challenges-item__section">
            <span
              class="pending-challenges-item__reservation"
              @click="selectReservation(duel.challenger)"
            >
              <span>{{ duel.challenger.eventName }}, {{ duel.challenger.startDate | simpleDate }}</span>
              <img src="../../assets/images/open_in_new.svg">
            </span>
          </div>
          <div class="pending-challenges-item__section">
            <span class="pending-challenges-item__text">Tiempo restante: </span>
            <countdown
              class="pending-challenges-item__time"
              :end-time="duel.endsAt"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="pending-challenges__block"
      v-if="duelsAsChallenged.length > 0"
    >
      <div class="pending-challenges__list">
        <div
          class="pending-challenges__item pending-challenges-item"
          v-for="duel in duelsAsChallenged"
          :key="duel.id"
        >
          <div class="pending-challenges-item__section">
            <span class="pending-challenges__title">
              {{ currentUser.producer ? 'Te están desafiando' : 'Reserva desafiada' }}
            </span>
          </div>
          <div class="pending-challenges-item__section">
            <span
              class="pending-challenges-item__reservation"
              @click="selectReservation(duel.challenged)"
            >
              <span>{{ duel.challenged.eventName }}, {{ duel.challenged.startDate | simpleDate }}</span>
              <img src="../../assets/images/open_in_new.svg">
            </span>
          </div>
          <div class="pending-challenges-item__section">
            <span class="pending-challenges-item__text">Tiempo restante: </span>
            <countdown
              class="pending-challenges-item__time"
              :end-time="duel.endsAt"
            />
          </div>
          <reservation-actions
            class="pending-challenges-item__actions"
            :reservation="duel.challenged"
            :only="['confirm', 'mark_as_zombie']"
          />
        </div>
      </div>
    </div>
    <div
      class="pending-challenges__block"
      v-if="transferAttempts.length > 0"
    >
      <div class="pending-challenges__list">
        <div
          class="pending-challenges__item pending-challenges-item"
          v-for="transferAttempt in transferAttempts"
          :key="transferAttempt.id"
        >
          <div class="pending-challenges-item__section">
            <span class="pending-challenges__title">
              Te quieren ceder esta fecha confirmada
            </span>
          </div>
          <div class="pending-challenges-item__section">
            <span
              class="pending-challenges-item__reservation"
              @click="selectTransferAttempt(transferAttempt)"
            >
              <p>Productora: {{ transferAttempt.reservation.producerName }}</p>
              <p>Fecha: {{ transferAttempt.reservation.startDate | justDate }}</p>
              <img src="../../assets/images/open_in_new.svg">
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as moment from 'moment-timezone';
import * as actions from '../store/action-types';
import ReservationActions from './reservation-actions';
import Countdown from './countdown';

export default {
  name: 'PendingChallenges',
  components: {
    ReservationActions,
    Countdown,
  },
  filters: {
    simpleDate(unformattedDate) {
      const date = moment(unformattedDate);

      return `${date.date()} de ${date.format('MMMM')}`;
    },
    calendarDate(date) {
      return moment(date).calendar();
    },
    fullDateTime(date) {
      return moment(date).format('DD-MM-YYYY HH:mm');
    },
    justDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.calendar.currentUser;
    },
    duelsAsChallenged() {
      return this.$store.getters.duelsAsChallenged;
    },
    duelsAsChallenger() {
      return this.$store.getters.duelsAsChallenger;
    },
    duels() {
      return this.$store.state.calendar.duels;
    },
    artists() {
      return this.$store.state.calendar.artists;
    },
    transferAttempts() {
      return this.$store.state.calendar.transferAttempts;
    },
  },
  methods: {
    close() {
      this.$store.dispatch(actions.TOGGLE_PENDINGS);
    },
    selectReservation(reservation) {
      this.$store.dispatch(actions.REDIRECT_TO_RESERVATION, reservation.id);
    },
    selectTransferAttempt(transferAttempt) {
      this.$store.dispatch(actions.REDIRECT_TO_TRANSFER_ATTEMPT, transferAttempt.id);
    },
  },
};
</script>
