import * as moment from 'moment-timezone';
import api from './api';

export default {
  get(date) {
    const path = '/api/v1/confirmation_attempts';

    return api({
      method: 'get',
      url: path,
      params: { date: moment(date).format('YYYY-MM-DD') },
    });
  },
};
