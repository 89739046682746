<template>
  <div class="countdown">
    <span><img src="../../assets/images/timer.svg" ></span>
    <span>{{ timeFromNow }}</span>
  </div>
</template>
<script>
import * as moment from 'moment-timezone';

const INTERVAL_TIME = 1000;

export default {
  name: 'Countdown',
  props: {
    endTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeFromNow: 0,
    };
  },
  methods: {
    refreshCountdownTimer() {
      const duration = moment.duration(moment(this.endTime).diff(moment(), 'ms'));
      const minutesAndSeconds = moment.utc(duration.as('milliseconds')).format('mm:ss');
      const hours = parseInt(duration.asHours(), 10);

      this.timeFromNow = `${hours}:${minutesAndSeconds}`;
    },
  },
  created() {
    this.refreshCountdownTimer();
    setInterval(this.refreshCountdownTimer, INTERVAL_TIME);
  },
  destroyed() {
    clearInterval(this.getTimeFromNow);
  },
};
</script>
