<template>
  <div
    class="calendar-cell"
    @mouseenter="onMouseEnter()"
    @mousedown="onMouseDown()"
    @mouseup="onMouseUp()"
    @mousemove="onMouseMove()"
    :class="{
      'calendar-cell--not-selected': !isSelectedDate,
      'calendar-cell--selected': isSelectedDate,
      'calendar-cell--empty': isEmpty,
      'calendar-cell--disabled': isDisabled,
    }"
  >
    <div
      class="calendar-cell__content--empty"
      v-if="isEmpty"
    />
    <div
      class="calendar-cell__content"
      v-else
    >
      <div class="calendar-cell__header">
        <span
          v-if="holidayName === ''"
          class="calendar-cell__day-number"
        >
          {{ date }}
        </span>
        <span
          v-if="holidayName !== ''"
          class="calendar-cell__day-number calendar-cell__holiday"
        >
          {{ date }} <strong>·</strong> FERIADO
        </span>
        <span
          v-if="holidayName !== ''"
          class="calendar-cell__holiday calendar-cell__holiday--name"
        >
          {{ date }} <strong>·</strong> {{ holidayName }}
        </span>
        <div
          class="calendar-cell__image-container"
          v-if="hasInactiveReservations"
          @mousedown.stop
          @mouseup.stop
        >
          <img
            class="calendar-cell__image-container--calendar-image"
            src='../../assets/images/inactive-reservations.svg'
            @click.stop="showInactiveReservations()"
          >
        </div>
      </div>
      <div
        class="calendar-cell-reservation calendar-cell__reservation"
        v-for="(reservation, index) in sortedReservations"
        :key="index"
        @click.stop="selectReservation(reservation)"
        @mouseup.stop
        @mouseenter.stop
        @mousedown.stop
        @mousemove.stop
      >
        <reservation-tag
          :reservation="reservation"
          :date="date"
          :month="month"
          :first-priority="firstPriority(reservation)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import * as actions from '../store/action-types';
import ReservationTag from './reservation-tag';

export default {
  name: 'CalendarCell',
  props: {
    date: {
      type: Number,
      default: () => 0,
    },
    isEmpty: {
      type: Boolean,
      default: () => false,
    },
    reservations: {
      type: Array,
      default: () => [],
    },
    inactiveReservations: {
      type: Array,
      default: () => [],
    },
    month: {
      type: Number,
      default: () => [],
    },
    year: {
      type: String,
      default: () => '2019',
    },
    holidayName: {
      type: String,
      default: () => '',
    },
  },
  components: {
    'reservation-tag': ReservationTag,
  },
  computed: {
    sortedReservations() {
      const sorted = [...this.reservations].sort((a, b) => a.queuePosition - b.queuePosition);
      const withEmpty = [];

      sorted.forEach(reservation => {
        withEmpty[reservation.queuePosition - 1] = reservation;
      });

      return withEmpty;
    },
    isSelectedDate() {
      return this.selectedStartDate && this.selectedEndDate &&
        this.date >= this.selectedStartDate &&
        this.date <= this.selectedEndDate;
    },
    selectedStartDate() {
      return this.$store.state.calendar.startDate;
    },
    selectedEndDate() {
      return this.$store.state.calendar.endDate;
    },
    isMouseOnDate() {
      return this.$store.state.calendar.mouseOnDate === this.date && !this.isSelectedDate;
    },
    isDisabled() {
      return moment().isSameOrAfter(moment({ year: this.year, month: this.month, day: this.date }).endOf('day'));
    },
    hasInactiveReservations() {
      if (this.$store.getters.inactiveReservationsGroupedByDay[this.date]) {
        return true;
      }

      return false;
    },
  },
  methods: {
    firstPriority(reservation) {
      return this.$store.getters.isFirstPriority(reservation, this.date);
    },
    selectReservation(reservation) {
      if (this.canSelectReservation(reservation)) {
        this.$store.dispatch(actions.SELECT_RESERVATION, reservation.id);
      }
    },
    showInactiveReservations() {
      this.$store.dispatch(actions.SHOW_INACTIVE_RESERVATIONS, this.date);
    },
    onMouseEnter() {
      if (!this.isEmpty && !this.isDisabled) {
        this.$store.dispatch(actions.MOUSE_ENTER_DATE, this.date);
      }
    },
    onMouseDown() {
      if (!this.isEmpty && !this.isDisabled) {
        this.$store.dispatch(actions.MOUSE_DOWN_DATE, this.date);
      }
    },
    onMouseUp() {
      if (!this.isEmpty && !this.isDisabled) {
        this.$store.dispatch(actions.MOUSE_UP_DATE, this.date);
      }
    },
    onMouseMove() {
      if (!this.isEmpty && !this.isDisabled) {
        this.$store.dispatch(actions.MOUSE_MOVE_DATE, this.date);
      }
    },
    canSelectReservation(reservation) {
      return !!reservation.eventName;
    },
  },
};
</script>
