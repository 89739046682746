import * as humps from 'humps';
import api from './api';

export default {
  get(newProducerId) {
    const path = '/api/v1/transfer_attempts';

    return api({
      method: 'get',
      params: humps.decamelizeKeys({ newProducerId }),
      url: path,
    });
  },
  create(params) {
    const path = '/api/v1/transfer_attempts';

    return api({
      method: 'post',
      data: humps.decamelizeKeys(params),
      url: path,
    });
  },
  update(params) {
    const path = `/api/v1/transfer_attempts/${params.id}`;

    return api({
      method: 'put',
      data: humps.decamelizeKeys(params),
      url: path,
    });
  },
};
