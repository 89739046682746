<template>
  <div class="modal">
    <div
      class="modal__mask"
      @click="closeModalIfClickOutside($event)"
    >
      <div class="modal__wrapper">
        <div
          class="modal__container"
          ref="modal-container"
        >
          <div class="modal__header">
            <slot name="header">
              {{ header }}
            </slot>
          </div>

          <div class="modal__body">
            <slot name="body">
              {{ body }}
            </slot>
          </div>

          <div class="modal__footer">
            <slot name="footer">
              {{ footer }}
              <button
                class="modal__button"
                @click="close()"
              >
                {{ button }}
              </button>
              <button
                v-if="hasAction"
                class="modal__button"
                @click="action()"
              >
                {{ actionName }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as actions from '../store/action-types';

export default {
  name: 'Modal',
  computed: {
    header() {
      return this.$store.state.calendar.modalData.header;
    },
    body() {
      return this.$store.state.calendar.modalData.body;
    },
    footer() {
      return this.$store.state.calendar.modalData.footer;
    },
    button() {
      return this.$store.state.calendar.modalData.button;
    },
    actionName() {
      return this.$store.state.calendar.modalData.action.name;
    },
    hasAction() {
      return this.actionName && this.$store.state.calendar.modalData.action.exec;
    },
  },
  methods: {
    close() {
      this.$store.dispatch(actions.CLOSE_MODAL);
    },
    action() {
      return this.$store.state.calendar.modalData.action.exec();
    },
    closeModalIfClickOutside(e) {
      const modalPosition = this.$refs['modal-container'].getBoundingClientRect();
      const isInsideY = e.clientY < modalPosition.bottom && e.clientY > modalPosition.top;
      const isInsideX = e.clientX > modalPosition.left && e.clientX < modalPosition.right;
      if (!(isInsideY && isInsideX)) {
        this.close();
      }
    },
  },
};
</script>
