<template>
  <div>
    <h1 class="sidebar__subform-title">
      Reagendar reserva
    </h1>
    <div class="sidebar__body">
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Motivo
        </div>
        <textarea
          id="reason"
          v-model="reason"
          class="input input--wide"
        />
      </div>
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Cargar Documento
        </div>
        <input
          id="document"
          name="document"
          type="file"
          class="input input--wide"
          @change="changeFile"
        >
      </div>
    </div>
    <div
      v-if="errors.length > 0"
      class="sidebar__errors"
    >
      <div
        class="sidebar__error field-error"
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </div>
    </div>
    <div class="reservation-form__actions sidebar__actions">
      <button
        v-if="formCompleted"
        class="sidebar__action"
        @click="confirmRescheduleReservation()"
      >
        Confirmar
      </button>
    </div>
  </div>
</template>
<script>

import { es } from 'vuejs-datepicker/dist/locale';
import * as actions from '../store/action-types';

export default {
  name: 'RescheduleForm',
  props: {
    reservation: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      reason: '',
      document: null,
      es,
      // eslint-disable-next-line no-undef
      rescheduleDocumentsEmail: process.env.RESCHEDULE_DOCUMENTS_EMAIL,
    };
  },
  computed: {
    errors() {
      return this.$store.state.calendar.errors;
    },
    confirmRescheduleReservationDataInfo() {
      return {
        header: 'Estás a punto de reagendar esta reserva ya confirmada',
        body: '¿Estás seguro de realizar esta acción?',
        button: 'Volver',
        action: {
          name: 'Sí, reagendar.',
          exec: this.rescheduleReservation,
        },
      };
    },
    rescheduleReservationDocumentsInfo() {
      return {
        header: 'Intento de reagendar ha sido creado',
        body: `Una vez que el reagendamiento sea aceptado, podrás reasignar esta reserva a una
               nueva. Para resolver cualquier consulta, escríbenos a ${this.rescheduleDocumentsEmail}`,
        button: 'Entendido',
      };
    },
    formCompleted() {
      return this.reason !== '' && this.document !== null;
    },
  },
  methods: {
    changeFile(e) {
      this.document = e.target.files[0];
    },
    rescheduleReservation() {
      this.$store.dispatch(actions.RESCHEDULE_RESERVATION, {
        reservationId: this.reservation.id,
        reason: this.reason,
        document: this.document,
      });
      this.$store.dispatch(actions.CLOSE_MODAL);
      this.$store.dispatch(actions.OPEN_MODAL, this.rescheduleReservationDocumentsInfo);
      this.$emit('reschedule-form');
    },
    confirmRescheduleReservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmRescheduleReservationDataInfo,
      );
    },
  },
};
</script>
