function sentence(array) {
  // eslint-disable-next-line no-magic-numbers
  const arrayStart = array.slice(0, -1).join(', ');
  // eslint-disable-next-line no-magic-numbers
  const arrayEnd = array.slice(-1);
  const conjunction = array.length <= 1 ? '' : ' y ';

  return [arrayStart, arrayEnd].join(conjunction);
}

export default sentence;
