/* eslint no-undef: 0 */

import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router';
import { camelizeKeys } from 'humps';
import ActionCableVue from 'actioncable-vue';
import { sync } from 'vuex-router-sync';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import vSelect from 'vue-select';

import Calendar from '../components/calendar';
import HeaderUser from '../components/header-user';
import store from '../store';
import router from '../router';

Vue.use(ActionCableVue, { connectionUrl: '/cable' });
Vue.use(VueRouter);

Vue.filter('camelizeKeys', camelizeKeys);
Vue.component('calendar', Calendar);
Vue.component('header-user', HeaderUser);
Vue.component('v-select', vSelect);

sync(store, router);

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('app') !== null) {
    if (process.env.RAILS_ENV === 'production') {
      Sentry.init({
        dsn: process.env.FRONT_SENTRY_DSN,
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
      });
    }

    return new Vue({
      el: '#app',
      store,
      router,
    });
  }

  return null;
});
