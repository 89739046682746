<template>
  <div>
    <h1 class="sidebar__title">
      Aceptar Cesión de Fecha
    </h1>
    <div class="sidebar__body">
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Artista
        </div>
        <v-select
          v-model="artistId"
          :options="artistsOptions"
          :reduce="artist => artist.id"
          @input="cleanErrors"
        />
      </div>
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Configuración
        </div>
        <v-select
          v-model="arenaTypeId"
          :options="arenaOptions"
          :reduce="type => type.id"
          @input="cleanErrors"
        />
      </div>
    </div>
    <div class="reservation-form__actions sidebar__actions">
      <button
        class="sidebar__action"
        @click="acceptTransferAttempt()"
      >
        Aceptar
      </button>
    </div>
  </div>
</template>
<script>
import 'vue-select/dist/vue-select.css';
import * as actions from '../store/action-types';

export default {
  name: 'AcceptTransferForm',
  props: {
    transferAttempt: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      artistId: null,
      arenaTypeId: null,
    };
  },
  computed: {
    artists() {
      return this.$store.getters.artistList;
    },
    arenaTypes() {
      return this.$store.state.calendar.arenaTypes;
    },
    artistsOptions() {
      return this.artists.map(artist => ({ label: artist.name, ...artist }));
    },
    arenaOptions() {
      return this.arenaTypes.map(type => ({ label: type.name, ...type }));
    },
    validForm() {
      return !!this.artistId && !!this.arenaTypeId;
    },
  },
  methods: {
    acceptTransferAttempt() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.acceptTransferAttemptDataInfo(),
      );
    },
    acceptTransferAttemptDataInfo() {
      return {
        header: 'Estás a punto de aceptar una cesión de fecha',
        body: '¿Estás seguro de realizar esta acción?',
        button: 'Volver',
        action: {
          name: 'Sí, aceptar',
          exec: this.submitForm,
        },
      };
    },
    submitForm() {
      if (this.validForm) {
        this.$store.dispatch(actions.UPDATE_TRANSFER_ATTEMPT, {
          id: this.transferAttempt.id,
          newArtistId: this.artistId,
          newArenaTypeId: this.arenaTypeId,
          state: 'accepted_by_new_producer',
        });
        this.$store.dispatch(actions.CLOSE_MODAL);
      }
    },
  },
};
</script>
