import axios from 'axios';
import * as humps from 'humps';
import router from '../router';

const NOT_AUTHORIZED = 401;

function api(options) {
  const CSRFToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  return axios({
    ...options,
    headers: {
      'X-CSRF-Token': CSRFToken,
      ...options.headers,
    },
  }).then(response => humps.camelizeKeys(response.data))
    .catch(error => {
      if (error.request.status === NOT_AUTHORIZED) {
        location.href = `/users/sign_in?user_return_to=${router.currentRoute.path}`;
      }

      return Promise.reject(error);
    });
}

export default api;
