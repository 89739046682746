<template>
  <div class="reservation-form">
    <div class="reservation-form__container">
      <div class="reservation-form__sidebar sidebar">
        <div
          class="sidebar__close"
          @click="close() + cleanErrors()"
        >
          <div class="close-icon" />
        </div>
        <h1 class="sidebar__title">
          Nueva reserva
        </h1>
        <div class="sidebar__body">
          <div class="sidebar__info sidebar__info--form field">
            <div class="sidebar__label">
              Productora
            </div>
            <div v-if="isAdmin">
              <v-select
                v-model="producerId"
                :options="producerOptions"
                :reduce="producer => producer.id"
                @input="cleanErrors"
              />
            </div>
            <div v-else>
              <span v-if="currentProducer">{{ currentProducer.name }}</span>
            </div>
          </div>
          <div class="sidebar__info sidebar__info--form field">
            <div class="sidebar__label">
              ¿La reserva es para un festival o evento corporativo?
            </div>
            <input
              type="radio"
              id="isNotFestivalRadio"
              :value="false"
              v-model="isFestival"
            >
            <label for="isNotFestivalRadio">No</label>
            <input
              type="radio"
              id="isFestivalRadio"
              :value="true"
              v-model="isFestival"
            >
            <label for="isFestivalRadio">Sí</label>
          </div>
          <div
            v-show="isFestival"
            class="sidebar__info sidebar__info--form field"
          >
            <div class="sidebar__label">
              Nombre del festival o evento
            </div>
            <input
              v-model="festivalName"
              @click="cleanErrors"
              class="input input--wide"
            >
          </div>
          <div class="sidebar__info sidebar__info--form field">
            <div class="sidebar__label">
              Artista
            </div>
            <v-select
              v-model="artistId"
              :options="artistsOptions"
              :reduce="artist => artist.id"
              @input="cleanErrors"
            />
          </div>
          <div class="sidebar__disclaimer">
            ¿Tu artista no está?
            <span
              class="sidebar__disclaimer-action"
              @click="openSuggestArtistForm()"
            >
              Sugerir uno
            </span>
          </div>
          <div class="sidebar__info sidebar__info--form field">
            <div class="sidebar__label">
              Configuración
            </div>
            <v-select
              v-model="arenaTypeId"
              :options="arenaOptions"
              :reduce="type => type.id"
              @input="cleanErrors"
            />
          </div>
          <div class="sidebar__info-double">
            <div class="sidebar__info sidebar__info--form field">
              <div class="sidebar__label">
                Inicio
              </div>
              <datepicker
                id="startDate"
                v-model="startDate"
                name="startDate"
                input-class="input input--date"
                calendar-class="input--date-selector"
                :format="inputDateFormat"
                :full-month-name="true"
                :monday-first="true"
                :language="es"
                :disabled-dates="disabledDates"
                @click="cleanErrors"
              />
            </div>
            <div class="sidebar__info sidebar__info--form field">
              <div class="sidebar__label">
                Fin
              </div>
              <datepicker
                id="endDate"
                v-model="endDate"
                name="endDate"
                input-class="input input--date"
                calendar-class="input--date-selector"
                :format="inputDateFormat"
                :full-month-name="true"
                :monday-first="true"
                :language="es"
                :disabled-dates="disabledDates"
                @click="cleanErrors"
                @selected="onEndDateManuallySelected"
              />
            </div>
          </div>
          <div class="sidebar__info-double">
            <div class="sidebar__info sidebar__info--form field">
              <div class="sidebar__label">
                Días de montaje
              </div>
              <input
                id="mountingDays"
                v-model="mountingDays"
                type="number"
                name="mountingDays"
                placeholder="Días de montaje"
                min="0"
                class="input input--number input--small"
                @click="cleanErrors"
              >
            </div>
            <div class="sidebar__info sidebar__info--form field">
              <div class="sidebar__label">
                Días de desmontaje
              </div>
              <input
                id="dismountingDays"
                v-model="dismountingDays"
                type="number"
                name="dismountingDays"
                placeholder="Días de desmontaje"
                min="0"
                class="input input--number input--small"
                @click="cleanErrors"
              >
            </div>
          </div>
        </div>
        <div
          v-if="errors.length > 0"
          class="sidebar__errors"
        >
          <div
            class="sidebar__error field-error"
            v-for="error in errors"
            :key="error"
          >
            {{ error }}
          </div>
        </div>
        <div class="reservation-form__actions sidebar__actions">
          <button
            class="sidebar__action"
            @click="confirmAddRseservation()"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
    <modal
      v-if="showArtistForm"
      ref="modal-container"
    >
      <template v-slot:header>
        Sugerir artista
      </template>

      <template v-slot:body>
        <div class="modal__input">
          <div class="modal__label">
            Nombre del artista o evento
          </div>
          <input
            id="suggestedArtist"
            v-model="suggestedArtist"
            name="suggestedArtist"
            placeholder="Artista o evento"
            class="input"
          >
        </div>
        <p
          class="modal__error"
          v-if="errorCreatingArtist"
        >
          Hubo un error. Escribe a reservas@movistararena.cl.
        </p>
      </template>

      <template v-slot:footer>
        <button
          class="modal__button"
          @click="closeSuggestArtistForm()"
        >
          Cerrar
        </button>
        <button
          class="modal__button"
          @click="suggestArtist()"
        >
          Enviar
        </button>
      </template>
    </modal>
  </div>
</template>
<script>

import * as moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale';
import 'vue-select/dist/vue-select.css';
import * as actions from '../store/action-types';
import * as mutations from '../store/mutation-types';
import modal from './modal';
import formatRate from '../filters/format-rate';

export default {
  name: 'ReservationForm',
  components: {
    Datepicker,
    modal,
  },
  props: {
    arenaTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      producerId: null,
      artistId: null,
      arenaTypeId: null,
      startDate: new Date(),
      endDate: new Date(),
      mountingDays: 0,
      dismountingDays: 0,
      festivalName: null,
      es,
      suggestedArtist: null,
      endDateManuallySelected: false,
      errorCreatingArtist: false,
      isFestival: false,
      disabledDates: {
        to: moment().startOf('day').toDate(),
      },
    };
  },
  mounted() {
    if (!this.isAdmin) {
      this.producerId = this.currentProducerId;
    }
    if (this.$store.state.calendar.startDate && this.$store.state.calendar.endDate) {
      this.startDate = this.calendarDate.set('date', this.$store.state.calendar.startDate).toDate();
      this.endDate = this.calendarDate.set('date', this.$store.state.calendar.endDate).toDate();
    } else if (moment().month() !== this.calendarDate.month()) {
      this.startDate = this.calendarDate.toDate();
      this.endDate = this.calendarDate.toDate();
    }
    this.subscribeToCreateArtistAction();
  },
  watch: {
    currentProducerId(val) {
      if (val) {
        this.producerId = val;
      }
    },
    reservationOptions: {
      handler: debounce(function (val) {
        this.$store.commit(mutations.SET_CURRENT_RATE, null);
        if (val.arenaTypeId) {
          this.$store.dispatch(actions.FETCH_RATE, { arenaTypeId: val.arenaTypeId, date: val.startDate });
        }
        // eslint-disable-next-line no-magic-numbers
      }, 500, { leading: true }),
      deep: true,
    },
    startDate(val) {
      if (moment(val).toDate().getTime() > moment(this.endDate).toDate().getTime() && !this.endDateManuallySelected) {
        this.endDate = moment.utc(val).toDate();
      }
    },
    selectedStartDate(val) {
      if (val) {
        this.startDate = this.calendarDate.set('date', val).toDate();
      }
    },
    selectedEndDate(val) {
      if (val) {
        this.endDate = this.calendarDate.set('date', val).toDate();
      }
    },
  },
  computed: {
    artistsOptions() {
      return this.artists.map(artist => ({ label: artist.name, ...artist }));
    },
    producerOptions() {
      return this.producers.map(producer => ({ label: producer.name, ...producer }));
    },
    arenaOptions() {
      return this.arenaTypes.map(type => ({ label: type.name, ...type }));
    },
    producers() {
      return this.$store.state.calendar.producers;
    },
    artists() {
      return this.$store.getters.artistList;
    },
    calendarDate() {
      return moment(this.$store.state.calendar.calendarDate);
    },
    isAdmin() {
      return this.$store.state.calendar.isAdmin;
    },
    currentProducerId() {
      return this.$store.state.calendar.currentProducerId;
    },
    currentProducer() {
      return this.producers.find(producer => producer.id === this.producerId);
    },
    errors() {
      return this.$store.state.calendar.errors;
    },
    selectedStartDate() {
      return this.$store.state.calendar.startDate;
    },
    selectedEndDate() {
      return this.$store.state.calendar.endDate;
    },
    showArtistForm() {
      return this.$store.state.calendar.showFormModal;
    },
    currentRate() {
      return this.$store.state.calendar.currentRate;
    },
    reservationOptions() {
      const { startDate, endDate, arenaTypeId } = this;

      return {
        startDate,
        endDate,
        arenaTypeId,
      };
    },
    formattedRate() {
      return formatRate(this.currentRate, {
        mountingDays: this.mountingDays,
        dismountingDays: this.dismountingDays,
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
  },
  methods: {
    subscribeToCreateArtistAction() {
      this.$store.subscribeAction((action) => {
        if (action.type === actions.CREATE_ARTIST_SUCCESSFUL) {
          this.closeSuggestArtistForm();
          this.$store.dispatch(
            actions.OPEN_MODAL,
            {
              header: 'Solicitud de artista enviada',
              body: 'Podrás seleccionarlo cuando sea aprobado por un administador.',
              button: 'Entendido',
            },
          );
        } else if (action.type === actions.CREATE_ARTIST_FAILURE) {
          this.errorCreatingArtist = true;
        }
      });
    },
    onEndDateManuallySelected() {
      this.endDateManuallySelected = true;
    },
    close() {
      this.$store.dispatch(actions.STOP_CREATING_RESERVATION);
    },
    fixDatesIfTimeZoneChange() {
      // If selected start date or end date is the day of a summer time change (start summer time or end summer time)
      // the selected date is saved as the previous day at 23:00. Adding one hour fixes the issue.
      if (moment(this.startDate).hour() === 23) { // eslint-disable-line no-magic-numbers
        this.startDate = moment(this.startDate).add(1, 'hours').toDate();
      }
      if (moment(this.endDate).hour() === 23) { // eslint-disable-line no-magic-numbers
        this.endDate = moment(this.endDate).add(1, 'hours').toDate();
      }
    },
    confirmAddRseservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmAddReservationDataInfo(),
      );
    },
    confirmAddReservationDataInfo() {
      return {
        header: 'Estás a punto de agregar una reserva',
        body: `¿Estás seguro de realizar esta acción?
          ${this.currentRate ? `Valor estimado:${this.formattedRate}` : ''}`,
        button: 'Volver',
        action: {
          name: 'Agregar',
          exec: this.submitForm,
        },
      };
    },
    submitForm() {
      this.fixDatesIfTimeZoneChange();
      if (this.isValidForm()) {
        this.$store.dispatch(actions.CREATE_RESERVATION, {
          producerId: this.producerId,
          artistId: this.artistId,
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.endDate).format('YYYY-MM-DD'),
          mountingDays: this.mountingDays,
          dismountingDays: this.dismountingDays,
          arenaTypeId: this.arenaTypeId,
          festivalName: this.festivalName,
        });
        this.$store.dispatch(actions.CLOSE_MODAL);
      }
    },
    validateProducer() {
      if (!this.producerId) {
        this.$store.dispatch(actions.ADD_RESERVATION_ERROR, 'Debes seleccionar un productor');
      }
    },
    validateArtist() {
      if (!this.artistId && !this.isFestival) {
        this.$store.dispatch(actions.ADD_RESERVATION_ERROR, 'Debes seleccionar un artista');
      }
    },
    validateDates() {
      if (!this.startDate || !this.endDate) {
        this.$store.dispatch(actions.ADD_RESERVATION_ERROR, 'Debes seleccionar una fecha de inicio y de fin');
      }
    },
    validateMountingAndDismounting() {
      if (this.mountingDays === null || this.dismountingDays === null) {
        this.$store.dispatch(actions.ADD_RESERVATION_ERROR, 'Debes ingresar días de montaje y desmontaje');
      }
    },
    validateFestivalName() {
      if (this.isFestival === true && this.festivalName === null) {
        this.$store.dispatch(actions.ADD_RESERVATION_ERROR, 'Debes ingresar nombre de festival');
      }
    },
    validateArenaType() {
      if (!this.arenaTypeId) {
        this.$store.dispatch(actions.ADD_RESERVATION_ERROR, 'Debes seleccionar una configuración del arena');
      }
    },
    isValidForm() {
      this.validateProducer();
      this.validateArtist();
      this.validateDates();
      this.validateMountingAndDismounting();
      this.validateFestivalName();
      this.validateArenaType();

      return this.errors.length === 0;
    },
    inputDateFormat(date) {
      return moment.utc(date).format('YYYY-MM-DD');
    },
    cleanErrors() {
      this.$store.dispatch(actions.REMOVE_RESERVATION_ERRORS);
    },
    openSuggestArtistForm() {
      this.$store.commit(mutations.SHOW_FORM_MODAL, true);
    },
    closeSuggestArtistForm() {
      this.$store.commit(mutations.SHOW_FORM_MODAL, false);
      this.suggestedArtist = null;
      this.errorCreatingArtist = false;
    },
    suggestArtist() {
      if (this.suggestedArtist && this.suggestedArtist.replace(/\s/g, '').length > 0) {
        this.$store.dispatch(actions.CREATE_ARTIST, {
          name: this.suggestedArtist,
        });
      }
    },
  },
};
</script>
