import * as moment from 'moment-timezone';
import api from './api';

export default {
  get(arenaTypeId, date) {
    const path = `/api/v1/arena_types/${arenaTypeId}/rate`;

    return api({
      method: 'get',
      url: path,
      params: { date: moment(date).format('YYYY-MM-DD') },
    });
  },
};
