<template>
  <div>
    <h1 class="sidebar__subform-title">
      Ceder la fecha
    </h1>
    <div class="sidebar__body">
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Productora a la que se le cede
        </div>
        <v-select
          v-model="producerId"
          :options="producerOptions"
          :reduce="producer => producer.id"
        />
      </div>
      <div class="sidebar__info sidebar__info--form field">
        <div class="sidebar__label">
          Motivo
        </div>
        <textarea
          id="reason"
          v-model="reason"
          class="input input--wide"
        />
      </div>
    </div>
    <div class="reservation-form__actions sidebar__actions">
      <button
        class="sidebar__action"
        @click="confirmTransferReservation()"
      >
        Confirmar
      </button>
    </div>
  </div>
</template>
<script>
import * as actions from '../store/action-types';

export default {
  name: 'TransferForm',
  props: {
    reservation: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      producerId: null,
      reason: '',
    };
  },
  computed: {
    producers() {
      return this.$store.state.calendar.producers.filter(producer => producer.id !== this.reservation.producerId);
    },
    producerOptions() {
      return this.producers.map(producer => ({ label: producer.name, ...producer }));
    },
  },
  methods: {
    confirmTransferReservation() {
      this.$store.dispatch(
        actions.OPEN_MODAL,
        this.confirmTransferReservationDataInfo(),
      );
    },
    confirmTransferReservationDataInfo() {
      return {
        header: 'Estás a punto de ceder esta reserva confirmada a otra productora',
        body: '¿Estás seguro de realizar esta acción?',
        button: 'Volver',
        action: {
          name: 'Sí, ceder',
          exec: this.transferReservation,
        },
      };
    },
    transferReservation() {
      this.$store.dispatch(
        actions.TRANSFER_RESERVATION,
        {
          newProducerId: this.producerId,
          reason: this.reason,
          reservationId: this.reservation.id,
        },
      );
      this.$store.dispatch(actions.CLOSE_MODAL);
      this.$emit('transfer-form');
    },
  },
};
</script>
