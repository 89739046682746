import * as humps from 'humps';
import * as moment from 'moment-timezone';
import api from './api';

export default {
  getReservations(date) {
    const path = '/api/v1/reservations';

    return api({
      method: 'get',
      url: path,
      params: { date: moment(date).format('YYYY-MM-DD') },
    });
  },
  getRescheduledReservations() {
    const path = '/api/v1/reservations';

    return api({
      method: 'get',
      url: path,
      params: { state: 'rescheduled' },
    });
  },
  getReservation(id) {
    const path = `/api/v1/reservations/${id}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  confirmReservation(reservationId) {
    const path = `/api/v1/reservations/${reservationId}/confirm`;

    return api({
      method: 'post',
      url: path,
    });
  },
  renewReservation(reservationId) {
    const path = `/api/v1/reservations/${reservationId}/renew`;

    return api({
      method: 'post',
      url: path,
    });
  },
  removeReservation(reservationId) {
    const path = `/api/v1/reservations/${reservationId}/remove`;

    return api({
      method: 'post',
      url: path,
    });
  },
  createReservation(reservation) {
    const path = '/api/v1/reservations';

    return api({
      method: 'post',
      data: { reservation: humps.decamelizeKeys(reservation) },
      url: path,
    });
  },
  transformToZombie(reservationId) {
    const path = `/api/v1/reservations/${reservationId}/mark_as_zombie`;

    return api({
      method: 'post',
      url: path,
    });
  },
  startConfirmationJourney(reservationId, tentativeRescheduledReservationId) {
    const path = `/api/v1/reservations/${reservationId}/start_confirmation_journey`;

    return api({
      method: 'post',
      data: humps.decamelizeKeys({ tentativeRescheduledReservationId }),
      url: path,
    });
  },
};
