import * as moment from 'moment-timezone';

function dateDayInCurrentMonth(startDate, currentDate, endDate) {
  if (moment(startDate).month() !== moment(currentDate).month()) {

    return [1, moment(endDate).date()];
  } else if (moment(endDate).month() !== moment(currentDate).month()) {

    return [moment(startDate).date(), moment(currentDate).endOf('month').date()];
  }

  return [moment(startDate).date(), moment(endDate).date()];
}

function hasReservationConfirmed(reservations) {
  return reservations.some(
    (reservation) => (reservation.state === 'confirmed' ||
      reservation.state === 'in_reschedule_process')
  );
}

function groupedByDay(reservations, calendarDate) {
  const groupedDays = {};
  const firstDay = moment(calendarDate);

  reservations.forEach(reservation => {
    const dates = dateDayInCurrentMonth(reservation.startMountingDate, firstDay, reservation.endDismountingDate);
    const [startDateDay, endDateDay] = dates;
    for (let day = startDateDay; day <= endDateDay; day++) {
      if (groupedDays[day]) {
        groupedDays[day].push(reservation);
      } else {
        groupedDays[day] = [reservation];
      }
    }
  });

  return groupedDays;
}

export default {
  reservations: state => () => state.reservations,
  inactiveReservations: state => () => state.inactiveReservations,
  selectedReservation: state => () =>
    state.reservations.find(reservation => reservation.id === state.selectedReservationId),
  isAdmin: state => () => state.isAdmin,
  currentProducerId: state => () => state.currentProducerId,
  confirmationAttempt: state => (reservation) =>
    state.confirmationAttempts
      .find(attempt => attempt.reservationId === reservation.id),
  artistList: state => Object.values(state.artists),
  rescheduledReservations: state => () => state.rescheduledReservations,
  duelsAsChallenged: (state) => {
    if (!state.currentUser.producer) {
      return state.duels;
    }

    return state.duels.filter(duel => duel.challenged.producerId === state.currentProducerId);
  },
  duelsAsChallenger: (state) => {
    if (!state.currentUser.producer) {
      return state.duels;
    }

    return state.duels.filter(duel => duel.challenger.producerId === state.currentProducerId);
  },
  reservationsGroupedByDay: (state) => groupedByDay(state.reservations, state.calendarDate),
  inactiveReservationsGroupedByDay: (state) => groupedByDay(state.inactiveReservations, state.calendarDate),
  selectionHasConfirmedReservations: (state, getters) => {
    const range = [
      ...Array(state.endDate - state.startDate + 1).keys(),
    ].map(i => i + state.startDate);

    return range.reduce((confirmedInRange, day) => {
      const reservations = getters.reservationsGroupedByDay[day];

      return confirmedInRange || (
        !!reservations && hasReservationConfirmed(reservations)
      );
    }, false);
  },
  isFirstPriority: (state, getters) => (reservation, day) => {
    const reservations = getters.reservationsGroupedByDay[day];
    const sorted = [...reservations]
      .sort((a, b) => a.queuePosition - b.queuePosition)
      .filter(res => res.state !== 'zombie');

    const firstReservation = sorted[0];
    if (!reservation || !firstReservation) return false;

    return reservation.state !== 'zombie' && (
      firstReservation.id === reservation.id ||
      reservation.firstPriority
    );
  },
};
